import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'whatwg-fetch';
import { withRouter } from 'react-router-dom';
import {
  AuthLayout,
  BoxedStrongLabel,
  FormContainer,
  PageLayout,
  SimpleInput,
  BirthdayInput,
  EmailInput
} from '@riotgames/account-feapp-commons';

import './RecoveryPage.scss';

class RecoveryPage extends Component {
  static propTypes = {
    update: PropTypes.func,
    config: PropTypes.object
  };

  constructor(props) {
    super(props);

    this.state = {
      realName: '',
      birthDate: '',
      email: ''
    };
  }

  acquirePI(authToken) {
    const { config, store } = this.props;

    console.log('token [' + store.state().transactionToken + ']');
    return fetch(config.api('/selfauth/v1/identity/' + authToken), {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + store.state().transactionToken,
        'Content-type': 'application/json;charset=UTF-8'
      },
      cache: 'no-cache',
      mode: 'cors'
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw Error('올바른 접근이 아니거나 입력 시간이 초과되었습니다.');
        }
      })
      .catch(e => {
        alert('서버와의 처리 과정에서 오류가 발생했습니다. 오류코드[' + e.status + ']');
      });
  }

  onVerified(authToken) {
    const { history, config, store } = this.props;
    const { puuid, transactionToken } = store.state();
    const { realName, birthDate, email } = this.state;

    store.update({
      transactionToken: transactionToken,
      realName: realName,
      birthDate: birthDate,
      email: email,
      playerAuthInfo: authToken
    });

    const payload = { realName: realName, birthDate: birthDate, email: email, playerAuthInfo: authToken };

    const handlers = {
      '200': { execute: () => history.push('/finish/' + puuid) },
      '404': { execute: () => alert('해당 계정은 존재하지 않습니다.') },
      '406': { execute: () => history.push('/unrecoverable/' + puuid) },
      '409': { execute: () => history.push('/consent/' + puuid) }
    };

    this.acquirePI(authToken).then(authResponse => {
      fetch(config.api('/recovery/v2/account/' + puuid), {
        method: 'PUT',
        cache: 'no-cache',
        mode: 'cors',
        headers: {
          Authorization: 'Bearer ' + transactionToken,
          'Content-type': 'application/json;charset=UTF-8'
        },
        body: JSON.stringify(payload)
      }).then(updateResponse => {
        const handler = handlers[updateResponse.status];
        if (handler) {
          const { age } = authResponse;
          store.update({ age: age });
          handler.execute();
        } else {
          alert('서버와의 처리 과정에서 오류가 발생되었습니다.[' + updateResponse.status + ']');
        }
      });
    });
  }

  validateAuthProceeding() {
    const { realName, birthDate, email } = this.state;
    const conditions = [
      { condition: realName !== '', msg: '이름을 입력해주세요.' },
      { condition: birthDate !== '', msg: '생년월일을 입력해주세요.' },
      { condition: email !== '', msg: '이메일 정보를 입력해주세요.' }
    ];
    const errorMsg = conditions.filter(constraint => !constraint.condition).map(constraint => constraint.msg);
    if (errorMsg && errorMsg.length > 0) {
      alert(errorMsg[0]);
      return false;
    } else {
      return true;
    }
  }

  componentDidMount() {
    const { match, store, config } = this.props;
    const { puuid } = match.params;
    if (!puuid || puuid.length <= 0) {
      alert('잘못된 접근입니다. 메인 페이지로 이동합니다.');
      config.toMain();
      return;
    }

    fetch(config.api('/signup/v1/account/token/rso'), {
      method: 'POST',
      cache: 'no-cache',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Content-type': 'application/json;charset=UTF-8'
      }
    }).then(response => {
      if (response.ok) {
        return response.json().then(payload => {
          store.update({ transactionToken: payload.accessToken, puuid: match.params.puuid });
        });
      } else {
        alert('올바른 접근이 아니거나 입력 시간이 초과되었습니다.');
      }
    });
  }

  render() {
    const { auth } = this.props.config;

    const title = '<strong>계정 정보 확인</strong>';
    const descriptions = [
      '회원정보 중 일부가 부정확하여 재확인이 필요합니다.',
      '확인하신 후 게임 및 홈페이지를 이용하실 수 있습니다.',
      '정확한 본인 확인을 위하여 회원정보 입력 및 본인인증을 진행해주시기 바랍니다.',
      '반드시 대상 계정에 입력되어 있는 정확한 정보를 입력하시기 바랍니다.'
    ];

    return (
      <div className="recovery-page">
        <PageLayout title={title} descriptions={descriptions}>
          <div className="list">
            <FormContainer title={'회원정보'}>
              <div>
                <div className="section">
                  <div className="label">이름</div>
                  <div className="component">
                    <SimpleInput
                      input={realName => this.setState({ realName: realName })}
                      placeholder={'홍길동'}
                      guideMessage={'실명을 입력해주세요.'}
                    />
                  </div>
                </div>
                <div className="section">
                  <div className="label">생년월일</div>
                  <div className="component">
                    <BirthdayInput
                      input={birthDate => this.setState({ birthDate: birthDate })}
                      placeholder={'20180101'}
                    />
                  </div>
                </div>
                <div className="section">
                  <div className="label">이메일</div>
                  <div className="component">
                    <EmailInput input={email => this.setState({ email: email })} />
                  </div>
                </div>
              </div>
            </FormContainer>
            <div>
              <BoxedStrongLabel label="<strong>회원정보 확인을 위한 본인인증 방법을 선택해주세요</strong>: 아이핀 또는 휴대전화를 통해 본인인증을 진행해주시기 바랍니다." />
              <AuthLayout
                identityCode={auth.appCode}
                env={auth.env}
                onVerified={this.onVerified.bind(this)}
                checkConfirm={this.validateAuthProceeding.bind(this)}
              />
            </div>
          </div>
        </PageLayout>
      </div>
    );
  }
}

export default withRouter(RecoveryPage);
