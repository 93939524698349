import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'whatwg-fetch';
import { withRouter } from 'react-router-dom';
import {
  AuthLayout,
  BoxedStrongLabel,
  ConsentLayout,
  FormContainer,
  PageLayout,
  SimpleInput,
  VerificationField,
  BirthdayInput
} from '@riotgames/account-feapp-commons';

import './ParentConsentsPage.scss';

class ConsentsPage extends Component {
  static propTypes = {
    config: PropTypes.object,
    store: PropTypes.object
  };

  constructor(props) {
    super(props);

    this.state = {
      parentRealName: '',
      parentBirthDate: '',
      parentEmail: '',
      textOnAges: {
        minor14: {
          title:
            '만 <strong>14세 미만</strong>은 <strong>개인정보 수집</strong> 및 <strong>게임 이용</strong>에 관한 <strong>법정대리인 동의</strong>가 필요합니다.',
          descriptions: [
            '라이엇 게임즈는 관련 법령을 준수하고자 만 14세 미만 미성년자의 개인정보 수집 및 활용에 대한 동의와 게임 이용에 관한 동의를 받고 있습니다. ',
            '라이엇 게임즈는 관련 법령에 따라 본 동의를 받기 위해 법정대리인의 성명 등 최소한의 개인정보를 수집 및 이용합니다.'
          ],
          consents: [
            {
              id: 'privacy',
              title: '미성년자 개인정보 수집 및 이용에 관한 동의서',
              endpoint: '/minor/privacy/14',
              content: '',
              isConfirmed: false
            },
            {
              id: 'gameplay',
              title: '미성년자의 게임 이용에 관한 동의',
              endpoint: '/minor/gameplay/14',
              content: '',
              isConfirmed: false
            },
            {
              id: 'parent',
              title: '법정대리인 개인정보 수집 및 이용에 관한 동의서',
              endpoint: '/parent/privacy/14',
              content: '',
              isConfirmed: false
            }
          ]
        },
        minor18: {
          title:
            '만 <strong>18세 미만</strong>의 경우 <strong>게임 이용</strong>에 관한 법정대리인의 <strong>동의</strong>가 필요합니다.',
          descriptions: [
            '라이엇 게임즈는 관련 법령을 준수하고자 만 18세 미만 미성년자의 게임 이용에 관한 법정대리인의 동의를 받고 있습니다.',
            '라이엇 게임즈는 관련 법령에 따라 본 동의를 받기 위해 법정대리인의 성명 등 최소한의 개인정보를 수집 및 이용합니다.'
          ],
          consents: [
            {
              id: 'gameplay',
              title: '미성년자의 게임 이용에 관한 동의',
              endpoint: '/minor/gameplay/18',
              content: '',
              isConfirmed: false
            },
            {
              id: 'parent',
              title: '법정대리인 개인정보 수집 및 이용에 관한 동의서',
              endpoint: '/parent/privacy/18',
              content: '',
              isConfirmed: false
            }
          ]
        }
      },
      consents: []
    };

    this.loadConsent = this.loadConsent.bind(this);
    this.onConsented = this.onConsented.bind(this);
    this.text = this.text.bind(this);
    this.composeConsent = this.composeConsent.bind(this);

    this.acquirePI = this.acquirePI.bind(this);
  }

  updateParentRealName(realName) {
    this.setState({ parentRealName: realName });
  }

  updateParentBirthDate(birthDate) {
    this.setState({ parentBirthDate: birthDate });
  }

  updateParentEmail(email, isVerified) {
    if (isVerified) {
      this.setState({ parentEmail: email });
    }
  }

  validateAuthProceeding() {
    const { consents, parentEmail, parentRealName, parentBirthDate } = this.state;
    const conditions = [
      {
        condition: consents.filter(consent => !consent.consented).length === 0,
        msg: '법정대리인의 동의 여부를 확인해주세요.'
      },
      { condition: parentRealName !== '', msg: '법정대리인의 실명을 입력해주세요.' },
      { condition: parentBirthDate !== '', msg: '법정대리인의 생년월일을 입력해주세요.' },
      { condition: parentEmail !== '', msg: '법정대리인 이메일 및 이메일 인증 여부를 확인해주세요.' }
    ];
    const errorMsg = conditions.filter(constraint => !constraint.condition).map(constraint => constraint.msg);
    if (errorMsg && errorMsg.length > 0) {
      alert(errorMsg[0]);
      return false;
    } else {
      return true;
    }
  }

  onVerified(authToken) {
    const { history, config, store } = this.props;
    const { api } = config;
    const { transactionToken, playerAuthInfo } = store.state();
    const { consents, parentEmail, parentRealName, parentBirthDate } = this.state;

    const parentalConsent = consents.filter(consent => !consent.consented).length === 0;

    this.acquirePI(authToken).then(payload => {
      if (
        parentalConsent &&
        payload.birthDate.replace(/-/g, '') === parentBirthDate &&
        payload.realName === parentRealName
      ) {
        fetch(
          api(
            '/signup/v1/account/token/' +
              encodeURIComponent(playerAuthInfo) +
              '/parent/token/' +
              encodeURIComponent(authToken)
          ),
          {
            method: 'GET',
            headers: {
              Authorization: 'Bearer ' + transactionToken,
              'Content-type': 'application/json;charset=UTF-8'
            },
            cache: 'no-cache',
            mode: 'cors'
          }
        ).then(response => {
          if (response.ok) {
            store.update({ parentEmail: parentEmail, parentAuthInfo: authToken });

            const { puuid, realName, birthDate, email, playerAuthInfo } = store.state();
            const payload = {
              realName: realName,
              birthDate: birthDate,
              email: email,
              playerAuthInfo: playerAuthInfo,
              parentEmail: parentEmail,
              parentAuthInfo: authToken
            };
            const handlers = {
              '200': { execute: () => history.push('/finish/' + puuid) },
              '404': { execute: () => alert('해당 계정은 존재하지 않습니다.') },
              '406': { execute: () => history.push('/unrecoverable/' + puuid) },
              '409': { execute: () => alert('법정보호자 동의 처리가 정상적이지 않습니다. 다시 한번 시도해주세요.') }
            };
            fetch(config.api('/recovery/v2/account/' + puuid), {
              method: 'PUT',
              cache: 'no-cache',
              mode: 'cors',
              headers: {
                Authorization: 'Bearer ' + transactionToken,
                'Content-type': 'application/json;charset=UTF-8'
              },
              body: JSON.stringify(payload)
            }).then(payload => {
              const handler = handlers[payload.status];
              if (handler) {
                handler.execute();
              } else {
                alert('서버와의 처리 과정에서 오류가 발생되었습니다.[' + payload.status + ']');
              }
            });
          } else {
            alert(
              '법정대리인 동의 오류입니다. 다음의 이유로 오류가 발생할 수 있습니다.\n' +
                '- 이전 계정 생성할 때 인증해준 법정대리인과 현재 법정대리인이 다른 경우\n' +
                '- 법정대리인과 미성년자의 나이 차이가 20살 미만인 경우\n' +
                '- 법정대리인이 이미 동의한 미성년자가 3명 이상인 경우'
            );
          }
        });
      } else {
        alert('동의 절차 및 올바른 보호자 정보를 확인 후 다시 진행해주시기 바랍니다.');
      }
    });
  }

  acquirePI(selfAuthResponseToken) {
    const { config, store } = this.props;

    return fetch(config.api('/selfauth/v1/identity/' + selfAuthResponseToken), {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + store.state().transactionToken,
        'Content-type': 'application/json;charset=UTF-8'
      },
      cache: 'no-cache',
      mode: 'cors'
    }).then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw Error('올바른 접근이 아니거나 입력 시간이 초과되었습니다.');
      }
    });
  }

  componentDidMount() {
    window.scrollTo(0, 280);

    this.composeConsent();
  }

  loadConsent(url, callback) {
    fetch(url, {
      method: 'GET',
      cache: 'no-cache'
    })
      .then(response => response.text())
      .then(callback);
  }

  onConsented(consentedId) {
    const { consents } = this.state;
    const consentedItem = consents.filter(({ id }) => id === consentedId);
    consentedItem[0].consented = !consentedItem[0].consented;
  }

  text() {
    const { state } = this.props.store;
    const { age } = state();
    const { textOnAges } = this.state;

    return age < 14 ? textOnAges.minor14 : textOnAges.minor18;
  }

  composeConsent() {
    const { config } = this.props;
    const { consents } = this.state;

    const text = this.text();

    text.consents.forEach(consent => {
      const { id, title, endpoint } = consent;
      this.loadConsent(config.api('/signup/v1/account/consent' + endpoint), text => {
        consents.push({ id: id, title: title, content: text, consented: false });
        this.setState({ consents: consents });
      });
    });
  }

  render() {
    const { api, auth } = this.props.config;
    const { state } = this.props.store;
    const { transactionToken } = state();
    const { consents } = this.state;
    const text = this.text();

    const consentUIs = consents.map(consent => {
      const { id, title, content } = consent;
      return (
        <div key={id} className="consent">
          <ConsentLayout
            id={id}
            title={title}
            consent={content}
            height={170}
            label="위 내용에 대해 동의합니다.(필수)"
            onConfirm={() => this.onConsented(id)}
          />
        </div>
      );
    });
    const checkEmailFormat = value =>
      /^(([^<>()\x5b\x5d\\.,;:\s@"]+(\.[^<>()\x5b\x5d\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value
      );

    return (
      <div className="parent-consents-page">
        <PageLayout title={text.title} descriptions={text.descriptions}>
          <div className="list">
            {consentUIs}
            <div className="top-space">
              <FormContainer title="법정대리인 정보">
                <div className="parent-info">
                  <div className="section">
                    <div className="label">법정대리인 성명</div>
                    <div className="component">
                      <SimpleInput input={this.updateParentRealName.bind(this)} />
                    </div>
                  </div>

                  <div className="section">
                    <div className="label">법정대리인 생년월일</div>
                    <div className="component">
                      <BirthdayInput
                        input={this.updateParentBirthDate.bind(this)}
                        guideMessage={'예: 1990년 1월 1일생의 경우, 19900101 입력'}
                      />
                    </div>
                  </div>

                  <div className="section">
                    <div className="label">법정대리인 이메일</div>
                    <div className="component">
                      <VerificationField
                        type="email"
                        name="메일"
                        guide="이메일 주소를 입력 후 인증 메일 발송 버튼을 눌러주세요."
                        timeLimit="600"
                        validated={checkEmailFormat}
                        api={api}
                        transactionToken={transactionToken}
                        out={this.updateParentEmail.bind(this)}
                      />
                    </div>
                  </div>
                </div>
              </FormContainer>
            </div>
            <div className="top-space">
              <div>
                <BoxedStrongLabel label="법정대리인 인증 방법을 선택해 주세요 : 동의 및 입력 완료 후 본인인증을 진행해주시기 바랍니다." />
              </div>
              <AuthLayout
                identityCode={auth.appCode}
                env={auth.env}
                onVerified={this.onVerified.bind(this)}
                checkConfirm={this.validateAuthProceeding.bind(this)}
              />
            </div>
          </div>
        </PageLayout>
      </div>
    );
  }
}

export default withRouter(ConsentsPage);
