import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import RecoveryPage from './RecoveryPage';
import ParentConsentPage from './ParentConsentsPage';
import FinishPage from '../FinishPage';
import UnrecoverablePage from '../UnrecoverablePage';

class RecoveryRoute extends Component {
  static propTypes = {
    config: PropTypes.object
  };

  constructor(props) {
    super(props);

    this.state = {
      transactionToken: '',
      puuid: '',
      realName: '',
      birthDate: '',
      email: '',
      parentEmail: '',
      playerAuthInfo: '',
      parentAuthInfo: '',
      age: 0
    };
  }

  render() {
    const { config } = this.props;
    const store = {
      state: () => this.state,
      update: state => this.setState(state)
    };

    return (
      <div>
        <Router basename="/recovery">
          <Switch>
            <Route
              path={'/consent/:puuid'}
              render={props => <ParentConsentPage config={config} store={store} {...props} />}
            />
            <Route path={'/finish/:puuid'} render={props => <FinishPage config={config} store={store} {...props} />} />
            <Route
              path={'/unrecoverable/:puuid'}
              render={props => <UnrecoverablePage config={config} store={store} {...props} />}
            />
            <Route path={'/:puuid'} render={props => <RecoveryPage config={config} store={store} {...props} />} />
          </Switch>
        </Router>
      </div>
    );
  }
}

export default RecoveryRoute;
