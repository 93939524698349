import React, { Component, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import FinishPage from './modules/FinishPage';
import FraudReportRoute from './modules/fraud/FraudReportRoute';
import RecoveryRoute from './modules/recovery/RecoveryRoute';
import './App.scss';

class App extends Component {
  render() {
    const appCode = process.env.REACT_APP_IDENTITY_APP_CODE;
    const config = {
      api: uri => process.env.REACT_APP_API_SERVER + uri,
      toLogin: uri => {
        const { protocol, host } = window.location;
        const url = protocol + '//' + host + uri;
        window.location.assign(process.env.REACT_APP_LOGIN_URL + '/rso/login?returnUrl=' + encodeURIComponent(url));
      },
      a12lUrl: process.env.REACT_APP_A12L_URL,
      toMain: url => {
        window.location.href = url ? url : process.env.REACT_APP_MAIN_URL;
      },
      toUri: uri => {
        const { protocol, host } = window.location;
        const url = protocol + '//' + host + uri;
        window.location.assign(url);
      },
      auth: { appCode: appCode, env: process.env.REACT_APP_ENV },
      captchaKey: process.env.REACT_APP_CAPTCHA_KEY
    };

    return (
      <div className="app">
        <Router basename="/">
          <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              <Route exact path={'/'} render={props => <FraudReportRoute config={config} {...props} />} />
              <Route path={'/fraud'} render={props => <FraudReportRoute config={config} {...props} />} />
              <Route path={'/recovery'} render={props => <RecoveryRoute config={config} {...props} />} />
              <Route path={'/finish'} render={props => <FinishPage {...props} />} />
            </Switch>
          </Suspense>
        </Router>
      </div>
    );
  }
}

export default App;
