import React, {Component} from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import FraudReportAgreement from './FraudReportAgreement';
import FraudReportRegister from './FraudReportRegister';
import FinishPage from '../FinishPage';

class FraudReportRoute extends Component {
  constructor(props) {
    super(props);

    this.state = {
      token: '',
      identityToken: null
    };
  }

  render() {
    const {config} = this.props;
    const store = {
      state: () => this.state,
      update: state => this.setState(state)
    };

    return (
      <div>
        <Router basename={'/fraud'}>
          <Switch>
            <Route exact path={'/report'} render={ (props) => <FraudReportRegister config={config} store={store} {...props} /> } />
            <Route exact path={'/finish'} render={ (props) => <FinishPage config={config} store={store} {...props} /> } />
            <Route path={'/:token'} render={ (props) => <FraudReportAgreement config={config} store={store} {...props} /> } />
          </Switch>
        </Router>
      </div>
    );
  }
};

export default FraudReportRoute;
