import React, { Component } from 'react';
import PropTypes from 'prop-types'
import {withRouter} from 'react-router-dom'
import {PageLayout, OrangeButton} from '@riotgames/account-feapp-commons';
import './UnrecoverablePage.scss'

class UnrecoverablePage extends Component {
  static propTypes = {
    config: PropTypes.object
  }

  render() {
    const {toMain} = this.props.config
    const {state} = this.props.location

    const defaultTitle = "<strong>회원정보 확인</strong>이 실패하였습니다."
    const defaultDescriptions = [
      "회원정보 확인이 실패하여 해당 계정을 정상적으로 이용하실 수 없습니다.",
      "정확한 회원정보를 입력하여 재확인 해주시기 바랍니다."
    ]

    const title = state ? state.title : defaultTitle
    const descriptions = state ? state.descriptions : defaultDescriptions;

    const markupDescriptions = descriptions.map(desc => (<p key={desc}>{desc}</p>))

    return (
      <div className="finish-page">
        <PageLayout title={title}>
          <div className="welcome">
            {markupDescriptions}
            <br/>
            <p>이용에 지속적인 문제가 있으시면 <a href="mailto:kor-account@riotgames.com">kor-account@riotgames.com</a> 으로 문의 해주시기 바랍니다.</p>
          </div>
          <div>
            <OrangeButton label="메인으로" click={toMain}/>
          </div>
        </PageLayout>
      </div>
    );
  }
}

export default withRouter(UnrecoverablePage);
